import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import * as React from 'react';
import { norrioCorporateName } from '../icons/NorrioIcon';

interface PageFooterProps {}

const PageFooter = ({}: PageFooterProps) => (
  <>
    <br />
    <Divider />
    <DetailContainer>
      <DetailField textAlign="center">
        <Typography variant="caption">
          Copyright &copy; 2022-{new Date().getFullYear()} {norrioCorporateName}
        </Typography>
      </DetailField>
    </DetailContainer>
  </>
);
export default PageFooter;
