import Button from '@mui/material/Button';
import { SnackbarKey, useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';

export interface AppVersionHook {}

export const useAppVersion = (): AppVersionHook => {
  const [currentVersion, setCurrentVersion] = useState<string>();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackbarKey, setSnackbarKey] = useState<SnackbarKey>();

  const origin = window.location.origin;

  useEffect(() => {
    if (currentVersion) {
      return;
    }

    // noinspection JSIgnoredPromiseFromCall
    updateCurrentVersion();
  }, []);

  useEffect(() => {
    if (!currentVersion) {
      return;
    }

    const timer = setInterval(
      async () => {
        await checkVersion();
      },
      5 * 60 * 1000,
    );

    return () => {
      clearInterval(timer);
    };
  }, [currentVersion]);

  const fetchVersion = async (): Promise<string | null> => {
    const response = await fetch(`${origin}/index.html`, { method: 'HEAD' });
    if (!response.ok) {
      console.error(`Error getting version: ${response.statusText} (${response.status})`);
      return null;
    }
    return response.headers.get('etag');
  };

  const updateCurrentVersion = async () => {
    const newVersion = (await fetchVersion()) || '';
    setCurrentVersion(newVersion);
  };

  const checkVersion = async () => {
    const newVersion = await fetchVersion();
    if (newVersion && newVersion !== currentVersion) {
      console.debug(`New version available. Current version: ${currentVersion}, new version: ${newVersion}`);
      // showVersionUpdateMessage();
      setCurrentVersion(newVersion);
    }
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const showVersionUpdateMessage = () => {
    closeSnackbar(snackbarKey);

    const newSnackbarKey = enqueueSnackbar(
      <span>
        There is a new version of the portal available. Please reload for the best experience.&nbsp;
        <Button size="small" color="inherit" onClick={() => handleRefresh()}>
          Reload
        </Button>
      </span>,
      {
        variant: 'info',
        persist: true,
      },
    );
    setSnackbarKey(newSnackbarKey);
  };

  return {};
};
